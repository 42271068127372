<template>
  <div>
    <div class="page-wrapper">
      <div class="m-t-40">
        <div class="authentication-box">
          <div class="text-center">
            <router-link to="/">
              <img src="../../assets/images/gurumandala-logo.png" alt="" style="width: 50%;">
            </router-link>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <div class="text-center">
                <h4>LOGIN</h4>
                <h6>Enter your Username and Password </h6>
              </div>
              <form class="theme-form">
                <b-form-group label="Username: ">
                  <b-form-input v-model="form.username" :state="hasError('username')" autofocus required></b-form-input>
                  <b-form-invalid-feedback :state="hasError('username')">{{getError('username')}}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Password: ">
                  <b-form-input @keydown.enter.prevent="submitForm" type="password" v-model="form.password" :state="hasError('password')" required></b-form-input>
                  <b-form-invalid-feedback :state="hasError('password')">{{getError('password')}}</b-form-invalid-feedback>
                </b-form-group>
                <div class="form-group mt-3 mb-0">
                  <button :disabled="form.username.length<3 || form.password.length<6" @click.prevent="submitForm" class="btn btn-square btn-primary btn-block" type="button">Login</button>
                  <router-link :to="{ name: 'register' }" class="btn btn-square btn-success btn-block">Register</router-link>
                  <router-link :to="{ name: 'reset-password' }" class="btn btn-block">Forgot Your Password ?</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      errorBag: null,
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    async submitForm() {
      try {
        let response = await this.$store.dispatch('auth/login', this.form)
        if( response.success ) {
          await this.$toasted.show('Successfully logged in',{
            fullWidth: true,
            fitToScreen: true,
            position: 'top-center',
            type : 'success',
          })
          await this.$router.push({name: 'home'})
        } else {
          this.errorBag = response.data
          console.log(response)
          await this.$toasted.error(response.message)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
